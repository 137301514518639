import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, IsActiveMatchOptions } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators'
import { SearchService } from '../../services/search.service';
import { ToggleSwitchComponent } from '../toggle-switch/toggle-switch.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  constructor(private searchService: SearchService, private router: Router) { }
  public sortToggle$!: Observable<boolean>;
  public currentRoute$!: Observable<string>;
  public onHomePage$!: Observable<boolean>;
  public onSearch$!: Observable<boolean>;
  public productCount$!: Observable<number>;

  ngOnInit(): void {
    this.sortToggle$ = this.searchService.sortingToggle$.pipe();

    this.currentRoute$ = this.router.events.pipe(
      map(e => this.router.url),
    )

    this.onHomePage$ = this.router.events.pipe(
      startWith(''),
      map(e => this.router.url.toLowerCase().includes('home'))
    );

    this.onSearch$ = this.router.events.pipe(
      startWith(''),
      map(e => this.router.url.toLowerCase().includes('search'))
    );

    this.productCount$ = this.searchService.results$.pipe(map(r => r.length));
  }

  searchIconClicked(currentRoute: string) {
    this.router.navigate(['search']);
  }
}
