import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PagedResult } from '../queries/paged-result';
import { StrapiQueryableService } from './strapi-queryable.service';
import { environment } from 'src/environments/environment';
import { Message, MessageDetails } from '../models/message';
import { MessageQuery } from '../queries/message-query';
import { SortDirections } from '../enumerations/sort-directions';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class MessageService extends StrapiQueryableService<Message, MessageDetails, MessageQuery> {

  constructor(protected httpClient: HttpClient) {
    super('messages', httpClient);
  }

  protected buildQuery(query?: MessageQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query fitlers.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }

      if (query.begin_date_lte) {
        queryObj._where.push({ begin_date_lte: query.begin_date_lte.toISOString() });
      }

      if (query.end_date_gte) {
        queryObj._where.push({ end_date_gte: query.end_date_gte.toISOString() });
      }

      if(!query.sortColumns || query.sortColumns.length === 0) {
        queryObj._sort = 'updated_at:DESC';
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: MessageQuery): PagedResult<MessageDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<MessageDetails[]>(`${environment.apiUrl}/${this.pathFragment}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: MessageQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${environment.apiUrl}/${this.pathFragment}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }
}
