import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { Base } from "../models/base";

export class StrapiService<TBase extends Base, TDetails extends Base> {
  private changed$: BehaviorSubject<string> = new BehaviorSubject('');
  private cache$?: Observable<TDetails[]>;

  constructor(protected pathFragment: string, protected httpClient: HttpClient) { }

  public getAll(): Observable<TDetails[]> {
    if (!this.cache$) {
      this.cache$ = this.changed$
        .pipe(
          switchMap(() => this.httpClient.get<TDetails[]>(`${environment.apiUrl}/${this.pathFragment}?_limit=10000`)),
          shareReplay(1)
        );
    }
    return this.cache$;
  }

  public get(id: number): Observable<TDetails> {
    return this.httpClient.get<TDetails>(`${environment.apiUrl}/${this.pathFragment}/${id}`)
      .pipe(
        shareReplay(1)
      );
  }
}
