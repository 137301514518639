import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, forwardRef, Output, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        right: '4px'
      })),
      state('closed', style({
        left: '4px'
      })),
      transition('open => closed', [
        animate('0.4s ease-in', 
        style({transform: 'translateX(-100%)'}))
      ]),
      transition('closed => open', [
        animate('0.4s ease-in', 
        style({transform: 'translateX(100%)'}))
      ]),
    ]),
  ],
})
export class ToggleSwitchComponent implements OnInit {

  @Input()
  leftText: string = "";
  @Input()
  rightText: string = "";

  public value$!: Observable<boolean>;

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
    this.value$ = this.searchService.sortingToggle$.pipe();
  }

  public clicked() {
    this.searchService.toggleSort();
  }
}
