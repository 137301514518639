import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SearchService } from 'src/app/shared/services/search.service';
import { ToggleSwitchComponent } from 'src/app/shared/components/toggle-switch/toggle-switch.component';
import { MessageService } from 'src/app/shared/services/message.service';
import { MessageDetails } from 'src/app/shared/models/message';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public toggle$!: Observable<boolean>;
  public messages$!: Observable<MessageDetails[]>;

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.messages$ = this.messageService.query({ begin_date_lte: new Date(), end_date_gte: new Date()}).records$;
  }

  ngAfterViewInit(): void {
  }

}
