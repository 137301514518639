import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { SearchCriteria } from '../models/search-criteria';
import { SearchCriteriaType } from '../models/search-criteria-type';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Blend } from '../models/blend';
import { Weave } from '../models/weave';
import { WeightCategory } from '../models/weight-category';
import { Width } from '../models/width';
import { Stretch } from '../models/stretch';
import { Product, ProductDetails } from '../models/product';
import { ProductService } from './product.service';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BlendService } from './blend.service';
import { WeaveService } from './weave.service';
import { WeightCategoryService } from './weight-category.service';
import { WidthService } from './width.service';
import { StretchService } from './stretch.service';
import { SortCriteria } from '../models/sort-criteria';
import { GetSearchCriteriaTypeRank } from '../utils/get-search-criteria-type-rank';
import { filter } from '../utils/filter-products';
import { BlendCategory, BlendCategoryDetails } from '../models/blend-category';
import { BlendCategoryService } from './blend-category.service';
import { WeaveCategoryService } from './weave-category.service';
import { WeaveCategoryDetails } from '../models/weave-category';
import { WeightCategoryType } from '../enumerations/weight-category-type';
import { getWeightCategoryType } from '../utils/weight-category-type-utils';
import { getProductWeightCategoryRank } from '../utils/get-product-weight-category-rank';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _sortingToggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sortingToggle$!: Observable<boolean>;

  products$!: Observable<ProductDetails[]>;
  results$!: Observable<ProductDetails[]>;
  
  private _searchCriteria$: BehaviorSubject<SearchCriteria[]> = new BehaviorSubject<SearchCriteria[]>([
    { type: SearchCriteriaType.Blend, selections: [], rank: 1},
    { type: SearchCriteriaType.Weave, selections: [], rank: 2},
    { type: SearchCriteriaType.Weight, selections: [], rank: 3},
    { type: SearchCriteriaType.Width, selections: [], rank: 5},
    { type: SearchCriteriaType.Stretch, selections: [], rank: 6}
  ])
  searchCriteria$!: Observable<SearchCriteria[]>;

  private _sortCriteria$: BehaviorSubject<SortCriteria[]> = new BehaviorSubject<SortCriteria[]>([
    { type: SearchCriteriaType.Blend, active: false, rank: 1 },
    { type: SearchCriteriaType.Weave, active: false, rank: 2 },
    { type: SearchCriteriaType.Weight, active: false, rank: 3},
    { type: SearchCriteriaType.WeightCategory, active: false, rank: 4},
    { type: SearchCriteriaType.Width, active: false, rank: 5},
    { type: SearchCriteriaType.Stretch, active: false, rank: 6 }
  ])
  sortCriteria$!: Observable<SortCriteria[]>;

  private accessorDictionary: Record<SearchCriteriaType,  ((product: ProductDetails) => string | number | boolean | undefined)> = {
    [SearchCriteriaType.Blend]: (c: ProductDetails) => { return c.blend?.name },
    [SearchCriteriaType.Weave]: (c: ProductDetails) => { return c.weave?.name },
    [SearchCriteriaType.Weight]: (c: ProductDetails) => { return c.weight },
    [SearchCriteriaType.WeightCategory]: (c: ProductDetails) => { return getProductWeightCategoryRank(c)},
    [SearchCriteriaType.Width]: (c: ProductDetails) => { return c.width?.name },
    [SearchCriteriaType.Stretch]: (c: ProductDetails) => { return !!c.hasStretch },
  };

  private _blends$!: Observable<Blend[]>;
  get blends$(): Observable<Blend[]> {
    if(!this._blends$) {
      this._blends$ = this.blendService.getAll().pipe(shareReplay(1));
    }
    return this._blends$;
  }

  private _weaves$!: Observable<Weave[]>;
  get weaves$(): Observable<Weave[]> {
    if(!this._weaves$) {
      this._weaves$ = this.weaveService.getAll().pipe(shareReplay(1));
    }
    return this._weaves$;
  }

  private _weights$!: Observable<WeightCategory[]>;
  get weights$(): Observable<WeightCategory[]> {
    if(!this._weights$) {
      this._weights$ = of([
        { id: 0, created_at: new Date(), updated_at: new Date(), name: 'Light Weight (< 5 oz.)'},
        { id: 1, created_at: new Date(), updated_at: new Date(), name: 'Medium Weight (5-7 oz.)'},
        { id: 2, created_at: new Date(), updated_at: new Date(), name: 'Heavy Weight (> 7 oz.)'}
      ]);
    }
    return this._weights$;
  }

  private _widths$!: Observable<Width[]>;
  get widths$(): Observable<Width[]> {
    if(!this._widths$) {
      this._widths$ = this.widthService.getAll().pipe(
        map(w => w.sort((a,b) => {
          let aVal = a?.name ?? '';
          let bVal = b?.name ?? '';
          return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;  
        })),
        shareReplay(1),    
      );
    }
    return this._widths$;
  }

  private _stretches$!: Observable<Stretch[]>;
  get stretches$(): Observable<Stretch[]> {
    if(!this._stretches$) {
      this._stretches$ = of([{ id: 0, created_at: new Date(), updated_at: new Date(), name: 'Yes'}, { id: 1, created_at: new Date(), updated_at: new Date(), name: 'No'}]);
    }
    return this._stretches$;
  }

  private _blendCategories$!: Observable<BlendCategoryDetails[]>;
  get blendCategories$(): Observable<BlendCategoryDetails[]> {
    if(!this._blendCategories$) {
      this._blendCategories$ = this.blendCategoryService.getAll().pipe(shareReplay(1));
    }
    return this._blendCategories$;
  }

  private _weaveCategories$!: Observable<WeaveCategoryDetails[]>;
  get weaveCategories$(): Observable<WeaveCategoryDetails[]> {
    if(!this._weaveCategories$) {
      this._weaveCategories$ = this.weaveCategoryService.getAll().pipe(shareReplay(1));
    }
    return this._weaveCategories$;
  }

  constructor(
    private productService: ProductService, 
    private blendService: BlendService, 
    private weaveService: WeaveService, 
    private weightCategoryService: WeightCategoryService, 
    private widthService: WidthService, 
    private stretchService: StretchService,
    private blendCategoryService: BlendCategoryService,
    private weaveCategoryService: WeaveCategoryService
  ) { 
    this.sortingToggle$ = this._sortingToggle$.asObservable();
    this.searchCriteria$ = this._searchCriteria$.asObservable();
    this.sortCriteria$ = this._sortCriteria$.asObservable();

    this.products$ = this.productService.getAll().pipe(
      shareReplay(1),
    );
    this.results$ = combineLatest([this.products$, this.searchCriteria$, this.sortCriteria$]).pipe(
      map(([products, searchCriteria, sortCriteria]) => {
        let filtered = filter(products, searchCriteria);    
        let sorted = this.applySorts(filtered, sortCriteria); 
        return sorted;
      })
    )
  }

  public toggleSort() {
    this._sortingToggle$.next(!this._sortingToggle$.value);
  }

  public overwriteCategories(searchCriteria: SearchCriteria[]) {
    Object.keys(SearchCriteriaType)
    .forEach(key => {
      if(!(searchCriteria.some(s => s.type === key))) {
        let type = SearchCriteriaType[key as keyof typeof SearchCriteriaType];
        searchCriteria.push({
          type: type,
          selections: [],
          rank: GetSearchCriteriaTypeRank(type)
        })
      }
    });
    if(searchCriteria && searchCriteria.length <= 6) {
      searchCriteria = searchCriteria.filter(s => s.type !== SearchCriteriaType.WeightCategory);
      this._searchCriteria$.next(searchCriteria);
    }    
  }

  public reorderCategories(previousIndex: number, newIndex: number) {
    let tmp: SearchCriteria[] = [];
    Object.assign(tmp, this._searchCriteria$.value);

    let active = tmp.filter(s => s.selections.length > 0);
    moveItemInArray(active, previousIndex, newIndex);

    let inactive = tmp.filter(s => s.selections.length == 0);
    inactive = inactive.sort((a,b) => {
      return a.rank > b.rank ? 1 : a.rank < b.rank ? -1 : 0
    })

    this._searchCriteria$.next([...active, ...inactive]);
  }

  public reorderSortCategories(previousIndex: number, newIndex: number) {
    let active = this._sortCriteria$.value.filter(s => s.active);
    let inactive = this._sortCriteria$.value.filter(s => !s.active);
    moveItemInArray(active, previousIndex, newIndex);
    this._sortCriteria$.next([...active, ...inactive]);
  }

  public toggleSortCategory(type: SearchCriteriaType) {
    let copy = this.cloneSortCriteriaArray(this._sortCriteria$.value);
    let sortCriteria = copy.find(s => s.type === type);
    if(sortCriteria) {
      sortCriteria.active = !sortCriteria.active;
    }

    let active = copy.filter(s => s.active);

    let inactive = copy.filter(s => !s.active);

    inactive = inactive.sort((a,b) => {
      return a.rank > b.rank ? 1 : a.rank < b.rank ? -1 : 0
    });

    copy = [...active, ...inactive];

    this._sortCriteria$.next(copy);
  }

  public addCriteria(type: SearchCriteriaType, criteria: Blend | Weave | WeightCategory | Width | Stretch) {
    let tmp: SearchCriteria[] = [];
    Object.assign(tmp, this._searchCriteria$.value);
    
    let searchCriteria = tmp.find(s => s.type === type);
    if(!searchCriteria?.selections.some(s => s.id === criteria.id)) {
      searchCriteria?.selections.push(criteria);
    }

    let active = tmp.filter(s => s.selections.length > 0);

    let inactive = tmp.filter(s => s.selections.length == 0);
    inactive = inactive.sort((a,b) => {
      return a.rank > b.rank ? 1 : a.rank < b.rank ? -1 : 0
    })

    tmp = [...active, ...inactive];

    this._searchCriteria$.next(tmp);
  }

  public removeCriteria(type: SearchCriteriaType, criteria: Blend | Weave | WeightCategory | Width | Stretch) {
    let tmp: SearchCriteria[] = [];
    Object.assign(tmp, this._searchCriteria$.value);
    
    let searchCriteria = tmp.find(s => s.type === type);

    let index = searchCriteria?.selections.findIndex(s => s.id === criteria.id);
    if(typeof(index) === 'number' && index !== -1) {
      searchCriteria?.selections.splice(index, 1)
    }

    let active = tmp.filter(s => s.selections.length > 0);

    let inactive = tmp.filter(s => s.selections.length == 0);
    inactive = inactive.sort((a,b) => {
      return a.rank > b.rank ? 1 : a.rank < b.rank ? -1 : 0
    });

    tmp = [...active, ...inactive];

    this._searchCriteria$.next(tmp);
  }

  public reorderCriteria(type: SearchCriteriaType, previousIndex: number, newIndex: number) {
    let tmp: SearchCriteria[] = [];
    Object.assign(tmp, this._searchCriteria$.value);
    
    let searchCriteria = tmp.find(s => s.type === type);
    if(searchCriteria) {
      moveItemInArray(searchCriteria.selections, previousIndex, newIndex);
    }

    this._searchCriteria$.next(tmp);
  }

  private applySorts(products: ProductDetails[], sortCriteria: SortCriteria[]): ProductDetails[] {
    let activeCriteria = sortCriteria.filter(s => s.active);
    if(activeCriteria.length === 0) {
      return products;
    }

    let copy = [...products].map(p => ({...p}));

    for(let criteria of activeCriteria) {
      if(!criteria.active) {
        continue;
      }

      if(criteria.type === SearchCriteriaType.Stretch) {
        copy = copy.sort((a, b) => {
          let aValue = a.stretch?.name ?? '';
          let bValue = b.stretch?.name ?? '';

          // Strip non-numeric characters (leave decimal sign)
          aValue = aValue.replace(/[^\d.-]/g, '');
          bValue = bValue.replace(/[^\d.-]/g, '');

          let aStretches = !!a.stretch || a.hasStretch;
          let bStretches = !!b.stretch || b.hasStretch;

          // Make products with no stretch whatsoever the least priority
          if(aStretches && !bStretches) {
            return 1;
          }
          else if(!aStretches && bStretches) {
            return -1;
          }

          // Make products with hasStretch = true and existing stretch highest priority
          if(!!a.stretch && !b.stretch) {
            return 1;
          }

          if(!a.stretch && b.stretch) {
            return -1;
          }

          // Treat the rest of the product stretches as numeric
          let aNumeric = parseFloat(aValue);
          let bNumeric = parseFloat(bValue);

          return aNumeric > bNumeric ? 1 : aNumeric < bNumeric ? -1 : 0;
        })
      }
      else if([SearchCriteriaType.Weight, SearchCriteriaType.WeightCategory].includes(criteria.type)) {
        copy = copy.sort((a, b) => {
          let aValue = this.accessorDictionary[criteria.type](a) ?? 0;
          let bValue = this.accessorDictionary[criteria.type](b) ?? 0;

          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        })
      }
      else {
        copy = copy.sort((a, b) => {
          let aValue = this.accessorDictionary[criteria.type](a) ?? '';
          let bValue = this.accessorDictionary[criteria.type](b) ?? '';

          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        })
      }      
    }

    return copy;
  }

  filterProductByStretch(product: ProductDetails, searchCriteria: SearchCriteria): boolean {
    let productHasStretch =  (this.accessorDictionary[searchCriteria.type](product) === true)    
    let showStretches = searchCriteria.selections.map(s => s.name?.toLowerCase()).includes('yes');
    let hideStretches = searchCriteria.selections.map(s => s.name?.toLowerCase()).includes('no');

    if(showStretches && !hideStretches) {
      return productHasStretch;
    }
    else if(!showStretches && hideStretches) {
      return !productHasStretch;
    }
    else if(!showStretches && !hideStretches) {
      return true;
    }
    else {
      return false;
    }
  }

  serializeCriteria(searchCriteria: SearchCriteria[]): string {
    let serializedCriteria = '';
    for(let sc of searchCriteria) {      
      serializedCriteria += `&${sc.type}`;
      for(let criteria of sc.selections) {
        serializedCriteria += `:${criteria.id}`
      }
    }

    return serializedCriteria;
  }

  deserializeCriteria(serializedCriteria: string): Observable<SearchCriteria[]> {
    return combineLatest([this.blends$, this.weaves$, this.weights$, this.widths$, this.stretches$]).pipe(
      map(([blends, weaves, weights, widths, stretches]) => {
        let searchCriteria: SearchCriteria[] = [];
        let searchCriteriaStrings = serializedCriteria.split('&').filter(s => !!s);

        for(let scString of searchCriteriaStrings) {
          let type = SearchCriteriaType[scString.split(':')[0] as keyof typeof SearchCriteriaType];
          let currentSearchCriteria: SearchCriteria = {
            type: type,
            selections: [],
            rank: GetSearchCriteriaTypeRank(type)
          }

          let criteriaIdStrings = scString.split(':').filter(s => s !== currentSearchCriteria.type);
          for(let id of criteriaIdStrings) {
            let currentCriteria: any | null = null;
            switch(currentSearchCriteria.type) {              
              case SearchCriteriaType.Blend:
                currentCriteria = blends.find(b => b.id === Number(id));
                break;
              case SearchCriteriaType.Weave: 
                currentCriteria = weaves.find(w => w.id === Number(id));
                break;
              case SearchCriteriaType.Weight: 
                currentCriteria = weights.find(w => w.id === Number(id));
                break;
              case SearchCriteriaType.Width: 
                currentCriteria = widths.find(w => w.id === Number(id));
                break;       
              case SearchCriteriaType.Stretch: 
                currentCriteria = stretches.find(s => s.id === Number(id));
                break;                                                     
            }
            if(currentCriteria) {
              currentSearchCriteria.selections.push(currentCriteria);
            }
          }
          searchCriteria.push(currentSearchCriteria);
        }
        return searchCriteria;
      })
    )
  }

  filterCriteriaHasRecords(type: SearchCriteriaType, selection: Blend | Weave | WeightCategory | Width | Stretch) {
    return combineLatest([this.searchCriteria$, this.results$, this.products$]).pipe(
      map(([searchCriteria, results, products]) => {        
        let criteriaCopy: SearchCriteria[] = this.cloneSearchCriteriaArray(searchCriteria);

        let criteria = criteriaCopy.find(s => s.type === type);

        if(!criteria) {
          return true;
        }

        criteria.selections.push(selection);

        let filteredProducts = (filter(products, criteriaCopy)).length;
        let affectsResults = (filteredProducts !== results.length) || (type === SearchCriteriaType.Stretch)
        return (filteredProducts > 0) && affectsResults
      })
    )
  }

  cloneSearchCriteriaArray(searchCriterias: SearchCriteria[]): SearchCriteria[] {
    let copy: SearchCriteria[] = [...searchCriterias].map(t => ({ ...t, selections: [...t.selections] }));
    return copy;
  }

  cloneSortCriteriaArray(sortCriterias: SortCriteria[]): SortCriteria[] {
    return [...sortCriterias].map(s => ({ ...s }));
  }

  clearSearchCriteria(type?: SearchCriteriaType) {
    let copy: SearchCriteria[] = [];
    Object.assign(copy, this._searchCriteria$.value);

    if(!!type) {
      let sc = copy.find(s => s.type === type);
      if(sc) {
        sc.selections = [];
      }
    }
    else {
      copy = copy.map(s => ({ ...s, selections: []}));
    }

    let active = copy.filter(s => s.selections.length > 0);

    let inactive = copy.filter(s => s.selections.length == 0);
    inactive = inactive.sort((a,b) => {
      return a.rank > b.rank ? 1 : a.rank < b.rank ? -1 : 0
    });

    copy = [...active, ...inactive];

    this._searchCriteria$.next(copy);
  }
}
