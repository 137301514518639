import { WeightCategoryType } from "../enumerations/weight-category-type";
import { Blend } from "../models/blend";
import { Product, ProductDetails } from "../models/product";
import { SearchCriteria } from "../models/search-criteria";
import { SearchCriteriaType } from "../models/search-criteria-type";
import { Stretch } from "../models/stretch";
import { Weave } from "../models/weave";
import { WeightCategory } from "../models/weight-category";
import { Width } from "../models/width";
import { getProductWeightCategoryRank } from "./get-product-weight-category-rank";
import { GetSearchCriteriaTypeRank } from "./get-search-criteria-type-rank";
import { getWeightCategoryType } from "./weight-category-type-utils";

const accessorDictionary: Record<SearchCriteriaType,  ((product: ProductDetails) => string | number | boolean | undefined)> = {
    [SearchCriteriaType.Blend]: (c: ProductDetails) => { return c.blend?.name },
    [SearchCriteriaType.Weave]: (c: ProductDetails) => { return c.weave?.name },
    [SearchCriteriaType.Weight]: (c: ProductDetails) => { return c.weight },
    [SearchCriteriaType.WeightCategory]: (c: ProductDetails) => { return getProductWeightCategoryRank(c) },
    [SearchCriteriaType.Width]: (c: ProductDetails) => { return c.width?.name },
    [SearchCriteriaType.Stretch]: (c: ProductDetails) => { return !!c.hasStretch },
}

const  weightCategoryDictionary: Record<number, WeightCategoryType> = {
  [0]: WeightCategoryType.Light,
  [1]: WeightCategoryType.Medium,
  [2]: WeightCategoryType.Heavy
};

export function filter(products: ProductDetails[], searchCriteria: SearchCriteria[]): ProductDetails[] {
  let productsCopy: ProductDetails[] = [];
  Object.assign(productsCopy, products);

  let stretchCriteria: SearchCriteria = searchCriteria.find(a => a.type === SearchCriteriaType.Stretch) ?? {type: SearchCriteriaType.Stretch, selections: [], rank: GetSearchCriteriaTypeRank(SearchCriteriaType.Stretch)};
  if(stretchCriteria.selections.length > 0) {
    productsCopy = productsCopy.filter(p => filterProductByStretch(p, stretchCriteria));
  }

  let activeCriteria = searchCriteria.filter(s => s.type !== SearchCriteriaType.Stretch && s.selections.length > 0);
  if(activeCriteria.length === 0) {
    return productsCopy;
  }

  return filterProducts(productsCopy, [], [], activeCriteria, 0);
}

function filterProducts(products: ProductDetails[], filteredResults: ProductDetails[], finalResults: ProductDetails[], searchCriteria: SearchCriteria[], categoryIndex: number = 0): ProductDetails[] {
  // The last filter has been applied
  if(categoryIndex === searchCriteria.length) {
    return filteredResults;
  }  

  let filtered: ProductDetails[] = [];

  for(let selection of searchCriteria[categoryIndex].selections) {
    // If filtering the first level of criteria, pull from the full products list
    if(categoryIndex === 0) {
      filtered = products.filter(p => filterProduct(p, searchCriteria[categoryIndex], selection));
    }
    // Otherwise filter the already-filtered results
    else {
      filtered = filteredResults.filter(r => filterProduct(r, searchCriteria[categoryIndex], selection));
    }

    for(let i = categoryIndex + 1; i < searchCriteria.length; i++) {
      filtered = filterProducts(products, filtered, finalResults, searchCriteria, i);
    }

    finalResults = finalResults.concat(filtered.filter(f => !finalResults.some(fr => fr.id === f.id)));
  }

  return finalResults;
}

function filterProduct(product: ProductDetails, searchCriteria: SearchCriteria, selection: Blend | Weave | WeightCategory | Width | Stretch): boolean {
  if(searchCriteria.type === SearchCriteriaType.Weight) {
    let weightCategoryType = weightCategoryDictionary[selection.id];
    let productWeightCategoryType = getWeightCategoryType(product);
    return weightCategoryType === productWeightCategoryType;
  }
  else {
    return accessorDictionary[searchCriteria.type](product) === selection.name;
  }
}

function filterProductByStretch(product: ProductDetails, searchCriteria: SearchCriteria): boolean {
  let productHasStretch =  (accessorDictionary[searchCriteria.type](product) === true)    
  let showStretches = searchCriteria.selections.map(s => s.name?.toLowerCase()).includes('yes');
  let hideStretches = searchCriteria.selections.map(s => s.name?.toLowerCase()).includes('no');

  if(showStretches && !hideStretches) {
    return productHasStretch;
  }
  else if(!showStretches && hideStretches) {
    return !productHasStretch;
  }
  else if(!showStretches && !hideStretches) {
    return true;
  }
  else {
    return false;
  }
}