<div class="h-100 d-flex flex-column overflow-auto">
    <div class="flex-fill">
        <div class="welcome-section text-white h-100 p-4 d-flex flex-column justify-content-between align-items-center">
            <div class="align-self-start">
                <img src="/assets/img/milliken-logo_white.png">
            </div>
            <h1 class="font-weight-bold font-size-jumbo welcome">WELCOME</h1>
            <!-- <div class="font-size-larger text-center welcome-body">Welcome Body Content Goes Here.</div> -->
            <carousel [isAnimated]="true" [showIndicators]="true" style="width: 100%; min-height: 100px;">
                <slide *ngFor="let msg of messages$ | async">
                    <div class="w-100 text-center">
                        <span class="font-size-larger messaging-title">{{msg.title}}</span>
                        <hr class="w-50" />
                        <span class="alerts font-size-large">{{msg.body}}</span>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
    <div class="flex-shrink-0 d-flex flex-column justify-content-center align-items-center">
        <div class="d-none flex-row w-100 justify-content-center align-items-center my-2" style="height: 50px; display: none;">
            <app-toggle-switch [leftText]="'Filter'" [rightText]="'Sort'"></app-toggle-switch>
        </div>
        <div class="w-100 my-4">
            <button class="font-size-xx-large btn btn-block btn-start" [routerLink]="['/search']">START SELECTING</button>
        </div>        
    </div>
</div>
