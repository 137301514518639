<div class="w-navbar bg-white d-flex flex-row justify-content-around align-items-center">
    <div class="nav-btn-outer" [routerLink]="['home']" routerLinkActive="nav-btn-outer-active">
        <div class="nav-btn-inner" style="padding-top:2px" [routerLink]="['home']" routerLinkActive="nav-btn-inner-active">
            <img [style.display]="(onHomePage$ | async) === true ? 'unset' : 'none'" class="icon pointer-events-none" src="/assets/svg/home_white.svg">
            <img [style.display]="(onHomePage$ | async) === false ? 'unset' : 'none'" class="icon pointer-events-none" src="/assets/svg/home_blue.svg">
        </div>
    </div>
    <div class="nav-btn-outer" [routerLink]="['results']" routerLinkActive="nav-btn-outer-active">
        <div class="nav-btn-inner small" [routerLink]="['results']" routerLinkActive="nav-btn-inner-active">
            <div *ngIf="productCount$ | async as productCount else noProducts" class="font-weight-bold font-size-medium product-count">{{productCount || 0}}</div>
            <ng-template #noProducts>
                <div class="font-weight-bold font-size-medium product-count">0</div>
            </ng-template>
        </div>
        <div class="styles-label font-size-x-small product-count">STYLES</div>
    </div>
    <div class="nav-btn-outer" [class.nav-btn-outer-active]="(onSearch$ | async) === true" *ngIf="(currentRoute$ | async) as currentRoute" (click)="searchIconClicked(currentRoute)">
        <div class="nav-btn-inner" [class.nav-btn-inner-active]="(onSearch$ | async) === true" style="padding-top: 5px">
            <img class="icon pointer-events-none" [style.display]="(sortToggle$ | async) === true ? 'unset' : 'none'" src="/assets/svg/sort_orange.svg">
            <img class="icon pointer-events-none" [style.display]="(sortToggle$ | async) === false && (onSearch$ | async) === false ? 'unset' : 'none'" src="/assets/svg/filter_blue.svg">
            <img class="icon pointer-events-none" [style.display]="(sortToggle$ | async) === false && (onSearch$ | async) === true ? 'unset' : 'none'" src="/assets/svg/filter_white.svg">
        </div>
    </div>
</div>
