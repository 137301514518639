import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PagedResult } from '../queries/paged-result';
import { StrapiQueryableService } from './strapi-queryable.service';
import { environment } from 'src/environments/environment';
import { Stretch, StretchDetails } from '../models/stretch';
import { StretchQuery } from '../queries/stretch-query';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class StretchService extends StrapiQueryableService<Stretch, StretchDetails, StretchQuery> {

  constructor(protected httpClient: HttpClient) {
    super('stretches', httpClient);
  }

  protected buildQuery(query?: StretchQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query fitlers.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: StretchQuery): PagedResult<StretchDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<StretchDetails[]>(`${environment.apiUrl}/${this.pathFragment}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: StretchQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${environment.apiUrl}/${this.pathFragment}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }
}
