import { ProductDetails } from "../models/product";

export function getProductWeightCategoryRank(product: ProductDetails): number {
    if(!product.weight) {
        return 0;
    }

    if(product.weight < 5) {
        return 0;
    }

    if(product.weight >= 5 && product.weight <= 7) {
        return 1;
    }

    if(product.weight > 7) {
        return 2;
    }

    return 0;
}