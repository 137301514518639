import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Product, ProductDetails } from '../models/product';
import { ProductQuery } from '../queries/product-query';
import { PagedResult } from '../queries/paged-result';
import { StrapiQueryableService } from './strapi-queryable.service';
import { environment } from 'src/environments/environment';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class ProductService extends StrapiQueryableService<Product, ProductDetails, ProductQuery> {

  constructor(protected httpClient: HttpClient) {
    super('products', httpClient);
  }

  protected buildQuery(query?: ProductQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query fitlers.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.productCategory) {
        queryObj._where.push({ productCategory: query.productCategory });
      }
      if (query.blend) {
        queryObj._where.push({ blend: query.blend });
      }
      if (query.weave) {
        queryObj._where.push({ weave: query.weave });
      }
      if (query.stretch) {
        queryObj._where.push({ stretch: query.stretch });
      }
      if (query.width) {
        queryObj._where.push({ width: query.width });
      }
      if (query.weightCategory) {
        queryObj._where.push({ weightCategory: query.weightCategory });
      }
      if (query.weight) {
        queryObj._where.push({ weight: query.weight });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: ProductQuery): PagedResult<ProductDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<ProductDetails[]>(`${environment.apiUrl}/${this.pathFragment}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: ProductQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${environment.apiUrl}/${this.pathFragment}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }
}
