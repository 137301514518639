import { WeightCategoryType } from "../enumerations/weight-category-type";
import { ProductDetails } from "../models/product";

export function getWeightCategoryType(product: ProductDetails): WeightCategoryType {
    if(!product.weight) {
        return WeightCategoryType.Light;
    }

    if(product.weight < 5) {
        return WeightCategoryType.Light;
    }

    if(product.weight >= 5 && product.weight <= 7) {
        return WeightCategoryType.Medium;
    }

    if(product.weight > 7) {
        return WeightCategoryType.Heavy;
    }

    return WeightCategoryType.Light;
}

export function getWeightCategoryLabel(product: ProductDetails) {
    if(!product.weight) {
        return 'Light Weight (< 5 oz.)';
    }

    if(product.weight < 5) {
        return 'Light Weight (< 5 oz.)';
    }

    if(product.weight >= 5 && product.weight <= 7) {
        return 'Medium Weight (5-7 oz.)';
    }

    if(product.weight > 7) {
        return 'Heavy Weight (> 7 oz.)';
    }

    return 'Light Weight (< 5 oz.)';
}