import { SearchCriteriaType } from "../models/search-criteria-type";

export function GetSearchCriteriaTypeRank(type: SearchCriteriaType) {
    switch(type) {
        case SearchCriteriaType.Blend:
            return 1;
        case SearchCriteriaType.Weave: 
            return 2;
        case SearchCriteriaType.Weight:
            return 3;
        case SearchCriteriaType.WeightCategory: 
            return 4;
        case SearchCriteriaType.Width:
            return 5;
        case SearchCriteriaType.Stretch:
            return 6;
    }
}